
export default {
  name: 'ChangePercent',
  props: {
    change: {
      type: Number,
      required: true,
    },
    square: {
      type: Boolean,
      default: true,
    },
    precision: {
      type: Number,
      default: 2,
    },
    paddingX: {
      type: Number,
      default: 1,
    },
    // Allows us to ignore mobile decimal restriction when displaying change percent
    ignoreMobileDecimalRestriction: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    changeClass() {
      let res = 'change-precent text-fs-1_5 text-fw-3 h-6';
      let colorClass = '';
      res += ` px-${this.paddingX}`;

      if (this.change < 0) {
        colorClass = this.square ? 'red-square' : 'red';
      } else if (this.change >= 0) {
        colorClass = this.square ? 'green-square' : 'green';
      }

      res += ` ${colorClass}`;
      return res;
    },
  },
};
