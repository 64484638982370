
import { mapActions, mapGetters } from 'vuex';
import Banner from '@/components/ui/atom/Banner';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';

export default {
  name: 'EmailValidationBannerWrapper',
  components: { Banner, CloseIcon },
  data() {
    return {
      mailStatusInterval: null,
    };
  },
  computed: {
    ...mapGetters('modals', [
      'helpCenterObjectRes',
      'hasClosedEmailConfirmationBanner',
    ]),
    ...mapGetters('authentication', ['isEmailConfirmed']),
    ...mapGetters('onboarding', ['hasCompletedTutorial']),
  },
  created() {
    this.mailStatusInterval = setInterval(this.getMailValidationStatus, 600000);
  },
  beforeDestroy() {
    clearInterval(this.mailStatusInterval);
  },
  methods: {
    ...mapActions('authentication', [
      'sendValidationMail',
      'getMailValidationStatus',
    ]),
    ...mapActions('modals', ['closeEmailConfirmationBanner']),
    handleClose() {
      this.closeEmailConfirmationBanner();
    },
  },
};
