
import MenuIcon from '~/assets/icons/menu-secondary.svg';
import CloseIcon from '~/components/ui/icons/CloseIcon.vue';
import AddIcon from '~/components/ui/icons/AddIcon.vue';
import draggable from 'vuedraggable';
import { mapActions } from 'vuex';

export default {
  name: 'Poll',
  components: {
    MenuIcon,
    AddIcon,
    CloseIcon,
    draggable,
  },
  props: {
    pollQuestion: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: ['', ''],
      canPost: false,
      maxOptions: 5,
      drag: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
  watch: {
    options() {
      this.canPostPoll();
    },
    pollQuestion() {
      this.canPostPoll();
    },
  },
  methods: {
    ...mapActions('post', ['createPoll']),
    emitPollClick() {
      this.$emit('poll-click');
    },
    addOption() {
      if (this.options.length < 5) {
        this.options.push('');
      }
    },
    removeOption(index) {
      this.options.splice(index, 1);
    },
    canPostPoll() {
      const allOptionsValid = this.options.every(
        (option) => option.trim() !== ''
      );
      const questionValid = this.pollQuestion !== '';
      this.canPost = allOptionsValid && questionValid;
    },
    async postPoll() {
      let order = 0;
      const pollList = this.options.map((option) => {
        const pollItem = {
          title: this.pollQuestion,
          option,
          order,
        };
        order++;
        return pollItem;
      });

      const poll = await this.createPoll(pollList);
      this.$emit('onCreatePoll', poll[0].pollId);
    },
  },
};
