export default {
  init(context) {
    context.commit('INIT');
  },
  getCryptoSymbols(context, payload) {
    const { noSkeletons, cryptoProfileId } = payload ?? {};

    if (!noSkeletons) context.commit('SET_CRYPTO_SYMBOLS_LOADING', true);

    this.dispatch('api/getCryptoSymbols', cryptoProfileId)
      .then((res) => {
        context.commit('SET_CRYPTO_SYMBOLS_TICKERS', res.data);
      })
      .catch((err) => {
        context.commit('SET_CRYPTO_SYMBOLS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_CRYPTO_SYMBOLS_LOADING', false);
      });
  },
};
