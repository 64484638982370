export const getCompanySymbolsRes = (state) => state.companySymbols;
export const companyHeadlines = (state) => state.headlines;
export const tickersToCompareRes = (state) => state.tickersToCompare;
export const hasPrimaryTickerFundamentals = (state) =>
  state.primaryTickerFundamentals.hasPrimaryTickerFundamentals;
export const primaryTickerFundamentalsLoading = (state) =>
  state.primaryTickerFundamentals.loading;
export const primaryTickerFundamentals = (state) =>
  state.primaryTickerFundamentals.data;
export const nextCompanyHeadlinesRes = (state) => state.nextHeadlines;
export const tickerFundamentalsRes = (state) => state.tickerFundamentals;
export const tickerFundamentalsLoading = (state) =>
  state.tickerFundamentals.loading;
export const currentlyActiveTicker = (state) => state.currentlyActiveTicker;
