
import { mapActions, mapGetters } from 'vuex';
import { isMobile } from '~/utils/screenSizes';
export default {
  name: 'CustomModal',
  props: {
    maxWidth: {
      type: String,
      default: 'screen-md',
    },
    width: {
      type: String,
      default: 'full',
    },
    background: {
      type: String,
      default: 'neutral-1',
    },
    headerShadow: {
      type: Boolean,
      default: true,
    },
    increasedFooter: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('modals', ['showPortfolioAssetOperationModalFooter']),
    widthClass() {
      return 'max-w-' + this.maxWidth + ' w-' + this.width;
    },
    // Using this for conditional rendering because tailwind classes cause unwanted behavior
    isMobileSize() {
      return isMobile();
    },
    backgroundClass() {
      return 'bg-' + this.background;
    },
  },
  created() {
    const body = document.body;
    body.classList.add('overflow-y-hidden');
  },
  beforeDestroy() {
    const body = document.body;
    body.classList.remove('overflow-y-hidden');
  },
  methods: {
    ...mapActions('modals', ['hideAllModals']),
  },
};
