
import { mapActions, mapGetters } from 'vuex';
import StarIcon from '~/components/ui/icons/StarIcon.vue';
import PortfolioIcon from '~/components/ui/icons/PortfolioIcon.vue';

export default {
  name: 'ModalPortfolioEmptyState',
  components: { StarIcon, PortfolioIcon },
  data() {
    return {
      open: true,
    };
  },
  async fetch() {
    await this.getProfile({ profileId: this.profileId });
  },
  computed: {
    ...mapGetters('modals', ['portfolioEmptyStateModal']),
    ...mapGetters('portfolio', ['createPortfolioRes', 'getMyPortfoliosRes']),
    ...mapGetters('profile', ['hasSeenWTPModalRes', 'profile']),
    ...mapGetters('authentication', ['profileId']),
  },
  methods: {
    ...mapActions('modals', [
      'showCreatePortfolioModal',
      'showPortfolioEmptyStateModal',
    ]),
    ...mapActions('profile', ['setWTPmodalSeen', 'getProfile']),
    handleClick() {
      this.showCreatePortfolioModal();
      this.showPortfolioEmptyStateModal();
      this.setWTPmodalSeen();
    },
    handleBack() {
      this.showPortfolioEmptyStateModal();
      this.setWTPmodalSeen();
    },
  },
};
