// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-show-create-post-rounded{background:var(--color-primary);border-radius:99px;bottom:99px;height:56px;position:fixed;right:10px;width:56px;z-index:2}@media (min-width:768px){.ui-show-create-post-rounded{bottom:auto;position:relative;right:auto}}.ui-show-create-post-rounded svg{padding:4px}.ui-show-create-post-rounded svg>*{stroke:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
