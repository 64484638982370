export default function(to, from, savedPosition) {
  let position = { x: 0, y: 0 };
  const nuxt = window.$nuxt;

  // TODO find a way to take this from the appURLs constant in utils/route-redirects.ts:38
  // TODO this must be in sync with the appURLs constant
  // NOTE: from the limited time provided to work on this ticket SKD-1581 Feedback point 33, I've not been able to get this module to do imports from other parts of our code function to do imports in this module
  /**
   * A map of routes to scroll restoration behaviors
   * @type {Record<string, 'auto' | 'instant' | 'smooth'>}
   * @description
   * This map is used to define the scroll restoration behavior for each route.
   * The key is the name of the route, and the value is the behavior to use.
   * The behavior can be one of the following:
   * - 'auto': The browser will determine the behavior
   * - 'instant': The scroll will be instant
   * - 'smooth': The scroll will be smooth
   * @see https://developer.mozilla.org/en-US/docs/Web/CSS/scroll-behavior
   */
  const scrollRestorationMap = {
    index: 'instant',
    market: 'instant'
  };

  // TODO find a way to import this from utils/routeNames.ts:13
  // NOTE: same remark as above applies
  // NOTE: this is copied over from utils/routeNames.ts:13
  /**
   * Removes the localization suffix from a route. Useful for checking which route the user is on in a localized nuxt app.
   * @param {NuxtRoute} route The route the user is currently on
   * @returns {string | undefined} The unlocalized route name
   */
  function unlocalizeRouteName(route) {
    // nuxt route names will have the name of the route in the following format
    // <route_name>__<locale>
    const localeSplitRoute = route.name?.split('__') ?? [];

    // in normal operation of the app, there will be two parts once the route name is split.
    // if there are more than two parts, one of the developers has given a poor name to the route.
    // this should be reported.
    if (localeSplitRoute.length > 2) {
      console.error(
        'This app does not support route names with double underscores. Rename your route',
        { route }
      );
    }

    // the name of the route without the locale at the end
    const unlocalizedRouteName = localeSplitRoute[0];

    return unlocalizedRouteName;
  }

  return new Promise((resolve) => {
    nuxt.$once('triggerScroll', () => {
      // if the user is on the same page or the hash has changed, do not scroll
      if (to.name === from.name || to.hash !== from.hash) {
        resolve(false);
      }
      // if there is a saved position, use it
      else if (savedPosition) {
        position = savedPosition;
      }
      // if there is a destination hash, use it as the destination position
      else if (to.hash) {
        position = {
          selector: to.hash
        };
      }

      const unlocalizedTo = unlocalizeRouteName(to);

      const behavior =
      unlocalizedTo in scrollRestorationMap ?
      scrollRestorationMap[unlocalizedTo] :
      'smooth';

      console.debug('scrollBehavior', {
        to,
        from,
        unlocalizedTo,
        behavior
      });

      resolve({ ...position, behavior: behavior });
    });
  });
}
