import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { Store } from 'vuex';
import { MessageNotificationDetail } from '~/types/messages/message-notifications';
import { RootState } from '~/store';
import { ContainsHubConnection } from '..';

let hubConnection: ContainsHubConnection | null = null;

export default async function messageNotificationsHub(
  store: Store<RootState>,
  jwt: string
): Promise<ContainsHubConnection> {
  if (hubConnection && hubConnection.hub) {
    if (hubConnection.hub.state === HubConnectionState.Connected) {
      return hubConnection; // Return the existing connection
    } else if (
      hubConnection.hub.state === HubConnectionState.Connecting ||
      hubConnection.hub.state === HubConnectionState.Reconnecting
    ) {
      return hubConnection;
    }
  }

  const hub = new HubConnectionBuilder()
    .withUrl(
      `${process.env.NUXT_ENV_API_URL}/subscribe/message-notifications`,
      {
        accessTokenFactory: () => jwt,
      }
    )
    .configureLogging(
      ['local', 'development'].includes(process.env.NODE_ENV ?? '')
        ? LogLevel.Debug
        : LogLevel.None
    )
    .build();

  hub.on('ReceiveMessageNotification', (not: MessageNotificationDetail) => {
    store.dispatch('messaging/addNewMessageNotification', not);
    store.dispatch('messaging/setMessageNotificationCount');
  });

  try {
    if (hub.state === HubConnectionState.Disconnected) {
      await hub.start();
    }
  } catch (error) {
    console.error('Error starting the SignalR connection:', error);
  }

  hubConnection = { hub };
  return { hub };
}
