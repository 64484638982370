/**
 * Formats URLs in the given content string to HTML anchor tags with blue color and underline style.
 *
 * @param {string} content - The content string to format.

 * @returns {string} The formatted content string with the og:url meta tag.
 */
export function formatPostContent(content: string): string {
  const formattedPostContent = content?.replace(
    linkRegex,
    (url) =>
      `<a href="${ensureLinkPrefixed(
        url
      )}" target="_blank" class="text-primary-6">${url}</a>`
  );

  return formattedPostContent ?? '';
}

/**
 * Checks if the given URL is valid.
 * @param url The URL to check.
 * @returns Whether the URL is valid.
 */
export function isValidUrl(url: string): boolean {
  try {
    new URL(url);
    return true;
  } catch (_) {
    return false;
  }
}

/**
 * Prefixes a protocol-less link with HTTPS.
 * If the link already has a protocol of HTTP or HTTPS, it will not be modified.
 * @param link The link to prefix.
 * @returns The link with a protocol.
 */
export function ensureLinkPrefixed(link: string): string {
  const protocol = link.match(/^(http|https):\/\//);

  // if the link does not have a protocol, add HTTPS by default
  return protocol ? link : `https://${link}`;
}

/**
 * Extracts all URLs from a given string.
 */
export const linkRegex = /(?:https?:\/\/|www\.)[^\s]+\.[^\s]+/g;
export const htmlRegex = /(<([^>]+)>)/gi;

/**
 * The URL of the Terms of Service page.
 */
export const tosUrl = process.env.NUXT_ENV_TOS_URL || '';

/**
 * The URL of the Privacy Policy page.
 */
export const privacyPolicyUrl = process.env.NUXT_ENV_PRIVACY_POLICY_URL || '';

/**
 * The URL of the About Us page
 */
export const aboutUsUrl = process.env.NUXT_ENV_ABOUT_US_URL || '';

/**
 * The URL of the Legal Notice page
 */
export const legalNoticeUrl = process.env.NUXT_ENV_LEGAL_NOTICE_URL || '';
