
import { mapActions, mapGetters } from 'vuex';

import Navbar from '~/components/NavigationBar/Navbar.vue';
import Sidebar from './sidebar/';
import Widgets from './widgets/';
import NavbarMobile from './navbarMobile/';
import { ComponentIds } from '~/utils/componentIds';

export default {
  name: 'Nav',
  components: {
    Navbar,
    Sidebar,
    Widgets,
    NavbarMobile,
  },
  props: {
    showWidgets: {
      type: Boolean,
      default: true,
    },
  },
  async fetch() {
    this.getMessageNotificationsAll({ userId: this.me.userId });
  },
  computed: {
    ...mapGetters('shared', ['showMobileSidebarRes', 'handleThemeRes']),
    ...mapGetters('authentication', [
      'me',
      'profile',
      'isConnected',
      'profileId',
      'isEmailConfirmed',
    ]),
    ...mapGetters('profile', ['profile', 'profileLoading']),
    widgetsContainerId() {
      return ComponentIds.WidgetsContainerId;
    },
    routeName() {
      return this.$route.name?.split('_')[0];
    },
    showCreatePostBtn() {
      return (
        this.isEmailConfirmed &&
        [
          'portfolios',
          'portfolios-portfolioId',
          'portfolios-settings',
          'portfolios-portfolio-symbol',
          'post-postId',
        ].indexOf(this.routeName) == -1 &&
        this.showCreatePostBtnOnProfile
      );
    },
    showCreatePostBtnOnProfile() {
      // returning true if we are on some route other than the profile route
      if (this.routeName !== 'profile-profileId') {
        return true;
      }
      return (
        !this.profileLoading &&
        (!this.profile || this.profile.profileType === 'PersonalProfile')
      );
    },
  },
  methods: {
    ...mapActions('modals', ['showCreatePostModal']),
    ...mapActions('shared', ['showMobileSidebar']),
    ...mapActions('messaging', ['getMessageNotificationsAll']),
    handleShowMobileSidebar() {
      if (this.showMobileSidebarRes) {
        this.showMobileSidebar();
      }
    },
  },
};
