import { render, staticRenderFns } from "./ComparePortfoliosModal.vue?vue&type=template&id=093b7104"
import script from "./ComparePortfoliosModal.vue?vue&type=script&lang=js"
export * from "./ComparePortfoliosModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/skade-pwa/components/ui/atom/buttons/Button.vue').default})
