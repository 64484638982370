export default {
  /// Symbols
  SYMBOLS_INIT: (state) => {
    state.companySymbols.data = [];
    state.companySymbols.error = null;
    state.companySymbols.loading = true;
  },
  SET_COMPANY_SYMBOLS_TICKERS: (state, payload) => {
    state.companySymbols.data = payload;
  },

  SET_COMPANY_SYMBOLS_ERROR: (state, er) => {
    state.companySymbols.error = er;
  },

  SET_COMPANY_SYMBOLS_LOADING: (state, loading) => {
    state.companySymbols.loading = loading;
  },

  /// Headlines
  HEADLINES_INIT: (state) => {
    state.headlines.data = [];
    state.headlines.error = null;
    state.headlines.loading = true;
  },

  SET_COMPANY_HEADLINES: (state, payload) => {
    state.headlines.data = payload;
  },

  SET_COMPANY_HEADLINES_ERROR: (state, er) => {
    state.headlines.error = er;
  },

  SET_COMPANY_HEADLINES_LOADING: (state, loading) => {
    state.headlines.loading = loading;
  },

  APPEND_COMPANY_HEADLINES: (state, data) => {
    state.nextHeadlines.data = data;
    state.headlines.data = [...state.headlines.data, ...data];
  },

  APPEND_COMPANY_HEADLINES_ERROR: (state, error) => {
    state.nextHeadlines.error = error;
  },

  APPEND_COMPANY_HEADLINES_LOADING: (state, loading) => {
    state.nextHeadlines.loading = loading;
  },

  /// Symbols to compare
  SYMBOLS_TO_COMPARE_INIT: (state) => {
    state.tickersToCompare.data = [];
    state.tickersToCompare.error = null;
    state.tickersToCompare.loading = true;
  },

  SET_TICKERS_TO_COMPARE: (state, payload) => {
    const ticker = payload.data.find(
      (item) => item.name === payload.companyName
    );

    if (ticker) {
      if (state.tickersToCompare.data === null) {
        state.tickersToCompare.data = [];
      }
      const tickerExists = state.tickersToCompare.data.find(
        (item) => item.name === payload.companyName
      );
      if (tickerExists === undefined) {
        state.tickersToCompare.data.push(ticker);
      }
    }
  },

  SET_MULTIPLE_TICKERS_TO_COMPARE_SUCCESS: (state, payload) => {
    if (state.tickersToCompare.data === null) {
      state.tickersToCompare.data = [];
    }

    payload.forEach((symbol) => {
      state.tickersToCompare.data.push(symbol);
    });
  },

  SET_MULTIPLE_TICKERS_TO_COMPARE_ERROR: (state, error) => {
    state.tickersToCompare.error = error;
    state.tickersToCompare.loading = false;
  },

  REMOVE_TICKER: (state, payload) => {
    const indexToRemove = state.tickersToCompare.data.findIndex(
      (item) => item.name === payload
    );

    if (indexToRemove !== -1) {
      state.tickersToCompare.data.splice(indexToRemove, 1);
    }
  },

  REMOVE_ALL_TICKERS: (state) => {
    state.tickersToCompare.data = [];
  },

  SET_TICKERS_TO_COMPARE_ERROR: (state, er) => {
    state.tickersToCompare.error = er;
  },

  SET_TICKERS_TO_COMPARE_LOADING: (state, loading) => {
    state.tickersToCompare.loading = loading;
  },

  RESET_TICKERS_TO_COMPARE: (state) => {
    state.tickersToCompare.data = null;
    state.tickersToCompare.error = null;
    state.tickersToCompare.loading = false;
  },

  // Fundamentals
  GET_FUNDAMENTALS_INIT: (state) => {
    state.primaryTickerFundamentals.hasPrimaryTickerFundamentals = true;
    state.primaryTickerFundamentals.data = null;
    state.primaryTickerFundamentals.loading = true;
    state.primaryTickerFundamentals.error = null;
  },

  SET_FUNDAMENTALS_SUCCESS: (state, payload) => {
    state.primaryTickerFundamentals.hasPrimaryTickerFundamentals = true;
    state.primaryTickerFundamentals.data = payload.data;
  },

  SET_FUNDAMENTALS_ERROR: (state, er) => {
    console.info(er);
    state.primaryTickerFundamentals.hasPrimaryTickerFundamentals = false;
    state.primaryTickerFundamentals.error = er;
  },

  SET_FUNDAMENTALS_LOADING: (state, isLoading) => {
    state.primaryTickerFundamentals.loading = isLoading;
  },

  GET_TICKER_FUNDAMENTALS_INIT: (state) => {
    state.tickerFundamentals.data = null;
    state.tickerFundamentals.error = null;
    state.tickerFundamentals.loading = true;
  },

  SET_TICKER_FUNDAMENTALS_SUCCESS: (state, data) => {
    state.tickerFundamentals.data = data;
  },

  SET_TICKER_FUNDAMENTALS_ERROR: (state, err) => {
    state.tickerFundamentals.error = err;
  },

  SET_TICKER_FUNDAMENTALS_LOADING: (state, loading) => {
    state.tickerFundamentals.loading = loading;
  },

  // Follow Symbol Price Change
  FOLLOW_SYMBOL_STOCK_PRICE_CHANGE_SUCCESS: (state, payload) => {
    if (state.companySymbols.data) {
      const symbol = state.companySymbols.data.find(
        (symbol) => symbol.symbolId === payload.symbolId
      );

      symbol.isFollowingPriceChange = !symbol.isFollowingPriceChange;
    }
  },

  SET_CURRENTLY_ACTIVE_TICKER: (state, ticker) => {
    state.currentlyActiveTicker = ticker;
  },
};
