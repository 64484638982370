import { showErrorToast } from '~/utils/toast-notifications';

export default {
  // Profile
  INIT_PROFILE: (state) => {
    state.symbolProfile = [];
    state.symbolProfileLoading = false;
    state.symbolProfileError = null;
  },
  SET_PROFILE: (state, payload) => {
    state.symbolProfile = payload;
  },
  SET_PROFILE_LOADING: (state, isLoading) => {
    state.symbolProfileLoading = isLoading;
  },
  SET_PROFILE_ERROR: (state, error) => {
    state.symbolProfileError = error;
    showErrorToast(window.$nuxt.$t('error_loading_symbol_profile'));
  },
  TOGGLE_PROFILE_FOLLOW: (state, payload) => {
    state.symbolProfile.companyProfileDetailDto.isFollowed = payload.isFollowed;
  },
};
