import { findAndReplace } from '@/utils';
import { showErrorToast } from '~/utils/toast-notifications';

export default {
  GET_FAVORITES_START: (state) => {
    state.feed = [];
    state.error = null;
    state.feedLoading = true;
  },
  GET_FAVORITES_SUCCESS: (state, data) => {
    state.feed = data;
    state.feedLoading = false;
  },
  GET_FAVORITES_ERROR: (state, error) => {
    state.error = error;
    state.feedLoading = false;
  },
  COUNT_NEW_POST_SUCCESS: (state, data) => {
    state.newPostNumber = data;
  },
  SET_ERROR: (state, error) => {
    state.error = error;
    showErrorToast(window.$nuxt.$t('error_favorite_page'));
  },
  SET_FEED: (state, payload) => {
    state.feed = payload;
  },
  UPDATE_POST: (state, payload) => {
    state.feed = findAndReplace(state.feed, 'postId', payload.postId, payload);
  },
  INCREMENT_REPLYCOUNT: (state, postId) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === postId);

      if (post) {
        post.replyCount++;
      }
    }
  },
  INCREMENT_REPOSTCOUNT: (state, postId) => {
    if (state.feed) {
      const post = state.feed.find((e) => e.postId === postId);

      if (post) {
        post.repostCount++;
      }
    }
  },
  APPEND_POSTS_TO_FEED: (state, payload) => {
    if (state.feed && state.feed.length > 0)
      state.feed = [...state.feed, ...payload];
  },
  APPEND_NEW_POST_AT_TOP: (state, post) => {
    if (state.feed) state.feed = [post, ...state.feed];
  },
  SET_FEED_LOADING: (state, isLoading) => {
    state.feedLoading = isLoading;
  },
  SET_NEXT_POSTS_LOADING: (state, isLoading) => {
    state.nextPostsLoading = isLoading;
  },
  REMOVE_POST_FROM_FEED: (state, post) => {
    const index = state.feed.findIndex((p) => p.postId === post.postId);
    state.feed.splice(index, 1);
  },
};
