import { showErrorToast } from '~/utils/toast-notifications';

export default {
  INIT: (state) => {
    state.industries = null;
    state.loading = false;
    state.error = false;
  },
  SET_INDUSTRIES: (state, payload) => {
    state.industries = payload;
  },
  SET_FOLLOWED_INDUSTRIES: (state, payload) => {
    state.industries = payload;
  },
  UPDATE_INDUSTRY: (state, industry) => {
    if (state.industries) {
      const index = state.industries.findIndex(
        (e) => e.industryId === industry.industryId
      );

      if (index !== -1) {
        const res = [...state.industries];

        res[index] = industry;
        state.industries = res;
      }
    }
  },
  TOGGLE_FOLLOW_INDUSTRY: (state, industries) => {
    if (state.industries) {
      const res = [...state.industries];

      industries.forEach((industry) => {
        const index = state.industries.findIndex(
          (e) => e.industryId === industry.industryId
        );
        if (index !== -1) {
          res[index].isFollowed = !res[index].isFollowed;
        }
      });
      state.industries = res;
    }
  },
  SET_LOADING: (state, isLoading) => {
    state.loading = isLoading;
  },
  SET_FOLLOWED_INDUSTRY_LOADING: (state, isLoading) => {
    state.loading = isLoading;
  },
  SET_ERROR: (state, err) => {
    state.error = err;
    showErrorToast(window.$nuxt.$t('error_loading_industries'));
  },
  SET_FOLLOWED_INDUSTRY_ERROR: (state, err) => {
    state.error = err;
    showErrorToast(window.$nuxt.$t('error_follow_industry'));
  },
};
