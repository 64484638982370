import { showToast, showErrorToast } from '~/utils/toast-notifications';

export default {
  likePost(context, payload) {
    this.dispatch('api/likePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  favoritePost(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_POST', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  favoriteReply(context, payload) {
    this.dispatch('api/favoritePost', payload)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  likeReply(context, payload) {
    this.dispatch('api/likePost', payload)
      .then((res) => {
        context.commit('UPDATE_REPLY', res.data);
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  likeNestedReply(context, payload) {
    this.dispatch('api/likePost', payload.nestedReplyId)
      .then((res) => {
        context.commit('UPDATE_NESTED_REPLY', {
          replyId: payload.replyId,
          nestedReply: res.data,
        });
      })
      .catch((err) => {
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  incrementPostReplyCount(context) {
    context.commit('INCREMENT_POST_REPLYCOUNT');
  },
  incrementNestedReplyReplyCount(context, payload) {
    context.commit('INCREMENT_NESTED_REPLY_REPLYCOUNT', payload);
  },
  incrementReplyReplyCount(context, payload) {
    context.commit('INCREMENT_REPLY_REPLYCOUNT', payload.postId);
  },
  incrementPostRepostCount(context) {
    context.commit('INCREMENT_POST_REPOSTCOUNT');
  },
  incrementReplyRepostCount(context, payload) {
    context.commit('INCREMENT_REPLY_REPOSTCOUNT', payload.postId);
  },
  incrementNestedReplyRepostCount(context, payload) {
    context.commit('INCREMENT_NESTED_REPLY_REPOSTCOUNT', payload);
  },
  getPost(context, payload) {
    context.commit('INIT_PAGE');
    context.commit('SET_POST_LOADING', true);
    return this.dispatch('api/getPostById', payload)
      .then((res) => {
        context.commit('SET_POST', res.data);
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_POST_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_POST_LOADING', false);
      });
  },
  setPost(context, post) {
    context.commit('SET_POST', post);
  },
  getReplies(context, payload) {
    context.commit('SET_REPLIES_LOADING', true);
    return this.dispatch('api/getPostReplies', payload)
      .then((res) => {
        context.commit('SET_REPLIES', res.data);
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_REPLIES_LOADING', false);
      });
  },
  postComment(context, payload) {
    return this.dispatch('api/createPost', payload)
      .then((res) => {
        showToast(window.$nuxt.$t('post_created_ok'));
        context.commit('APPEND_NEW_REPLY', res.data);
        context.commit('INCREMENT_NEW_REPLY_COUNT');
      })
      .catch((err) => {
        showErrorToast(window.$nuxt.$t('error_occured_message'));
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  getNextReplies(context, payload) {
    context.commit('SET_NEXT_REPLIES_LOADING', true);
    return this.dispatch('api/getPostReplies', payload)
      .then((res) => {
        context.commit('APPEND_REPLIES', res.data);
      })
      .catch((err) => {
        console.error(err);
        context.commit('SET_REPLIES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_NEXT_REPLIES_LOADING', false);
      });
  },
  getNestedReplies(context, payload) {
    context.commit('SET_REPLIES_LOADING', true);
    this.dispatch('api/getPostReplies', payload)
      .then((res) => {
        if (res.data && res.data.length > 0)
          context.commit('SET_NESTED_REPLIES', {
            postId: payload.postId,
            replies: res.data,
          });
        context.commit('SET_NESTED_REPLY_VIEW_MORE', {
          postId: payload.postId,
          show: res.data.length == payload.params.limit,
        });
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  getNextNestedReplies(context, payload) {
    this.dispatch('api/getPostReplies', payload)
      .then((res) => {
        if (res.data && res.data.length > 0)
          context.commit('APPEND_NESTED_REPLIES', {
            postId: payload.postId,
            replies: res.data,
          });
        context.commit('SET_NESTED_REPLY_VIEW_MORE', {
          postId: payload.postId,
          show: res.data.length == payload.params.limit,
        });
      })
      .catch((err) => {
        console.dir(err);
        context.commit('SET_REPLIES_ERROR', err);
      });
  },
  appendNewNestedReply(context, payload) {
    const parentReply = context.state.replies.find(
      (r) => r.postId == payload.postId
    );
    if (parentReply.showLoadMore) return;

    context.commit('APPEND_NEW_NESTED_REPLY', payload);
  },
  setRepliesLoading(context, isLoading) {
    context.commit('SET_REPLIES_LOADING', isLoading);
  },
  setIsLoadFinished(context, isLoadFinished) {
    context.commit('SET_IS_LOAD_FINISHED', isLoadFinished);
  },
  getPoll(context, payload) {
    this.dispatch('api/getPoll', payload)
      .then((res) => {
        context.commit('SET_POLL', { pollId: payload.id, pollData: res.data });
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  getPollResults(context, payload) {
    return this.dispatch('api/getPollResults', payload)
      .then((res) => {
        return res.data.length ? true : false;
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
        return false;
      });
  },
  createPoll(context, payload) {
    return this.dispatch('api/createPoll', payload)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
  votePoll(context, payload) {
    this.dispatch('api/votePoll', payload)
      .then((res) => {
        context.commit('UPDATE_POLL', res.data);
      })
      .catch((err) => {
        context.commit('SET_ERROR', err);
      });
  },
};
