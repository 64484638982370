// eslint-disable-next-line @typescript-eslint/no-unused-vars
const recommendationLimit = 5;

export default {
  init(context) {
    context.commit('SYMBOLS_INIT');
    context.commit('HEADLINES_INIT');
    context.commit('TICKERS_INIT');
  },
  getCompanySymbols(context, payload) {
    const { noSkeletons, companyId } = payload ?? {};

    if (!noSkeletons) context.commit('SET_COMPANY_SYMBOLS_LOADING', true);

    this.dispatch('api/getCompanySymbols', companyId)
      .then((res) => {
        context.commit('SET_COMPANY_SYMBOLS_TICKERS', res.data);
      })
      .catch((err) => {
        context.commit('SET_COMPANY_SYMBOLS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_COMPANY_SYMBOLS_LOADING', false);
      });
  },
  getCompanyHeadlines(context, payload) {
    const { noSkeletons, profileId, limit, page } = payload ?? {};

    if (!noSkeletons) context.commit('SET_COMPANY_HEADLINES_LOADING', true);

    this.dispatch('api/getCompanyHeadlines', {
      profileId: profileId,
      limit: limit,
      page: page,
    })
      .then((res) => {
        context.commit('SET_COMPANY_HEADLINES', res.data);
      })
      .catch((err) => {
        context.commit('SET_COMPANY_HEADLINES_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_COMPANY_HEADLINES_LOADING', false);
      });
  },
  getMoreCompanyHeadlines(context, payload) {
    context.commit('APPEND_COMPANY_HEADLINES_LOADING', true);
    return this.dispatch('api/getCompanyHeadlines', {
      profileId: payload.profileId,
      limit: payload.limit,
      page: payload.page,
    })
      .then((res) => {
        context.commit('APPEND_COMPANY_HEADLINES', res.data);
      })
      .catch((err) => {
        context.commit('APPEND_COMPANY_HEADLINES_ERROR', err);
      })
      .finally(() => {
        context.commit('APPEND_COMPANY_HEADLINES_LOADING', false);
      });
  },
  getTickersToCompare(context, payload) {
    context.commit('SET_TICKERS_TO_COMPARE_LOADING', true);
    this.dispatch('api/getCompanySymbols', payload.companyId)
      .then((res) => {
        context.commit('SET_TICKERS_TO_COMPARE', {
          data: res.data,
          companyName: payload.companyName,
        });
      })
      .catch((err) => {
        context.commit('SET_TICKERS_TO_COMPARE_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TICKERS_TO_COMPARE_LOADING', false);
      });
  },
  resetTickersToCompare(context) {
    context.commit('RESET_TICKERS_TO_COMPARE');
  },
  getMultipleTickersToCompare(context, payload) {
    context.commit('SET_TICKERS_TO_COMPARE_LOADING', true);
    this.dispatch('api/getMultipleCompanySymbols', payload)
      .then((res) => {
        context.commit('SET_MULTIPLE_TICKERS_TO_COMPARE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('SET_MULTIPLE_TICKERS_TO_COMPARE_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TICKERS_TO_COMPARE_LOADING', false);
      });
  },
  removeTicker(context, payload) {
    context.commit('REMOVE_TICKER', payload);
  },
  removeAllTickers(context) {
    context.commit('REMOVE_ALL_TICKERS');
  },
  clearTickersToCompare(context) {
    context.commit('SYMBOLS_TO_COMPARE_INIT');
  },
  getPrimaryTickerFundamentals(context, payload) {
    context.commit('GET_FUNDAMENTALS_INIT');
    this.dispatch('api/getPrimaryTickerFundamentals', payload.companyId)
      .then((res) => {
        context.commit('SET_FUNDAMENTALS_SUCCESS', {
          data: res.data,
          companyName: payload.companyName,
        });
      })
      .catch((err) => {
        context.commit('SET_FUNDAMENTALS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_FUNDAMENTALS_LOADING', false);
      });
  },
  getTickerFundamentals(context, payload) {
    const { noSkeletons, ticker } = payload ?? {};

    if (!noSkeletons) context.commit('SET_TICKER_FUNDAMENTALS_LOADING', true);

    return this.dispatch('api/getTickerFundamentals', ticker)
      .then((res) => {
        context.commit('SET_TICKER_FUNDAMENTALS_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_FUNDAMENTALS_ERROR', err);
      })
      .finally(() => {
        context.commit('SET_TICKER_FUNDAMENTALS_LOADING', false);
      });
  },
  followSymbolStockPriceChange(context, payload) {
    this.dispatch('api/followStockChange', payload)
      .then(() => {
        context.commit('FOLLOW_SYMBOL_STOCK_PRICE_CHANGE_SUCCESS', payload);
      })
      .catch((err) => {
        context.commit('SET_COMPANY_SYMBOLS_ERROR', err);
      });
  },

  setCurrentlyActiveTicker(context, ticker) {
    context.commit('SET_CURRENTLY_ACTIVE_TICKER', ticker);
  },
};
