// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-banner-container{bottom:92px;left:0;margin-left:auto;margin-right:auto;max-width:1000px;min-width:40%;position:fixed;right:0;z-index:10}@media (min-width:768px){.ui-banner-container{bottom:30px}}.ui-banner-container .ui-banner{border:1px solid;min-height:60px;padding-left:20px;padding-right:20px;position:relative;z-index:10}.ui-banner-container .ui-banner .link{cursor:pointer;font-weight:500}@media (max-width:768px){.ui-banner-container .ui-banner{padding-right:10px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"a": "#5a80fb",
	"b": "#6b88fc",
	"c": "#7b91fc",
	"d": "#8899fd",
	"e": "#95a2fd",
	"f": "#a1abfe",
	"g": "#adb4fe",
	"h": "#b8bdfe",
	"i": "#c3c6ff",
	"j": "#cdcfff",
	"k": "#d8d9ff",
	"l": "#e2e2ff",
	"m": "#ececff",
	"n": "#f5f5ff",
	"o": "#fff"
};
module.exports = ___CSS_LOADER_EXPORT___;
