
import { mapActions, mapGetters } from 'vuex';
import UISearchSymbol from '@/components/ui/organism/UISearchSymbol';
import { getWindowInstance } from '~/plugins/windowInstance';
import { debounce } from 'lodash';

export default {
  name: 'SearchSymbolWrapper',
  components: { UISearchSymbol },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    inputError: {
      type: String,
      default: '',
    },
    searchType: {
      type: String,
      default: 'symbol',
    },
    showSearchLabel: {
      type: Boolean,
      default: false,
    },
    searchFieldFocused: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('search', ['searchRes']),
    ...mapGetters('modals', ['createPortfolioOperationModal']),
  },
  methods: {
    ...mapActions('modals', ['showPortfolioAssetOperationModalFooter']),
    search: debounce(mapActions('search', ['search']).search, 200),

    /**
     * Fired by the UISearchSymbol when there's a
     * @param {string} query - The user query.
     */
    onSearchRequest(query) {
      if (query) {
        if (window.innerWidth < 786) {
          if (this.searchRes.data) this.scrollSearchSymbolWrapperIntoView();
          this.showPortfolioAssetOperationModalFooter(false);
        }
        this.search({ type: this.searchType, query, limit: 10 });
      }
    },
    handleClickAdd(symbol) {
      this.$emit('onAddSymbol', symbol);
      this.showPortfolioAssetOperationModalFooter(true);
    },
    async handleSearchSymbolWrapperClick() {
      if (this.isMobileResponsive()) {
        this.scrollSearchSymbolWrapperIntoView();
        if (this.searchRes.data) {
          this.showPortfolioAssetOperationModalFooter(false);
        }
      }
    },
    scrollSearchSymbolWrapperIntoView() {
      const wrapper = this.$refs.searchSymbolWrapper;
      if (wrapper) {
        setTimeout(() => {
          wrapper.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
        }, 200);
      }
    },
    isMobileResponsive() {
      // @ts-ignore TODO fix typescript not recognizing this
      const test = getWindowInstance(this).isMobileResponsive();
      return test;
    },
  },
};
