import actions from './actions';
import mutations from './mutations';
import * as getters from './getters';

const state = () => ({
  // Profile
  contentStep: 0,
  contentMode: 'followers',
  profile: null,
  profileLoading: false,
  profileError: null,
  showProfileEditModal: false,
  hasSeenWTPModal: false,
  blocks: null,
  reports: null,
  // Profile feed
  feed: null,
  feedLoading: false,
  feedError: null,
  nextPostLoading: false,
  // Profile followers
  followers: null,
  followersLoading: false,
  followersPage: 1,
  nextFollowersLoading: false,
  followersError: null,
  showLoadMoreFollowers: false,
  // Profile followed
  followed: null,
  followedLoading: false,
  followedPage: 1,
  nextFollowedLoading: false,
  followedError: null,
  showLoadMoreFollowed: false,

  createProfile: { data: null, loading: null, error: null },
  deleteProfile: { data: null, loading: false, error: null },
});

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
