
import WIcon from '@/components/ui/icons/WIcon';

/**
 * Represents a button as defined in the Skade Figma.
 */
export default {
  components: {
    WIcon,
  },
  props: {
    /**
     * Controls the button appearance.
     */
    type: {
      type: String,
      default: 'primary',
      validator: (v) =>
        [
          'primary',
          'secondary',
          'outline',
          'danger',
          'neutral',
          'success',
        ].includes(v),
    },
    /**
     * The underlying HTML button type.
     * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button
     */
    htmlType: {
      type: String,
      default: 'submit',
      validator: (v) => ['submit', 'reset', 'button'].includes(v),
    },
    /**
     * Represents a left icon symbol. If null, no icon is rendered.
     */
    iconLeft: {
      type: String,
      default: null,
    },
    /**
     * Represents a right icon symbol. If null, no icon is rendered.
     */
    iconRight: {
      type: String,
      default: null,
    },
    /**
     * Represents the component size.
     */
    size: {
      type: String,
      default: 'normal',
      validator: (v) => ['small', 'normal', 'large', 'xl', 'auto'].includes(v),
    },
    /**
     * Represents the padding vertical of the button.
     */
    paddingVertical: {
      type: String,
      default: 'py-2.5',
      validator: (v) => ['py-0', 'py-2', 'py-2.5', 'py-3'].includes(v),
    },
    /**
     * The text displayed in the button.
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * Specifies the state in which the button currently is.
     */
    state: {
      type: String,
      default: 'enabled',
      validator: (v) => ['enabled', 'disabled', 'loading'].includes(v),
    },
    /**
     * Enables animations.
     */
    animated: {
      type: Boolean,
      default: false,
    },
    /**
     * Additional classes to apply to the container of this component
     */
    containerClasses: {
      type: String,
      default: '',
      required: false,
    },
  },
  emits: ['click'],
  computed: {
    height() {
      return {
        small: 'h-7',
        normal: 'h-8',
        large: 'h-9',
        xl: 'h-12',
        auto: 'h-auto',
      }[this.size];
    },
    classes() {
      return [
        ...(this.animated && this.state === 'loading'
          ? ['wbutton--animated']
          : [
              `wbutton--${this.type}--${this.state}`,
              `wbutton--${this.type}`,
              `wbutton--${this.state}`,
            ]),
        this.state === 'disabled' ? 'cursor-not-allowed' : '',
        this.height,
        this.containerClasses ? this.containerClasses : '',
      ];
    },
  },
};
