
import { VEmojiPicker } from 'v-emoji-picker';
import MediaBar from '@/components/ui/molecule/MediaBar';
import GiphyModal from '@/components/ui/molecule/GiphyModal';
import PollCreate from '@/components/ui/molecule/PollCreate';
import MultipleImageDisplay from '@/components/ui/molecule/MultipleImageDisplay';
import PostBox from '@/components/ui/molecule/PostBox';
import { PostDisabledReason } from '~/types/models/post';
import { getMedia } from '@/utils/mixins';
import UIPost from '@/components/ui/organism/UIPost';
import { isMobile } from '~/utils/screenSizes';

export default {
  name: 'UICreatePost',
  components: {
    VEmojiPicker,
    MediaBar,
    GiphyModal,
    PollCreate,
    MultipleImageDisplay,
    PostBox,
    UIPost,
  },
  mixins: [getMedia],

  props: {
    searchMentions: {
      type: Function,
      required: true,
    },
    searchHashtags: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    showEditIcon: {
      type: Boolean,
      default: true,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    uploadImages: {
      type: Function,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
    mentionedPost: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Used to provide a way for external components to submit the post
     */
    submitFromExternal: {
      type: Boolean,
      default: false,
    },
    /**
     * If the submit comes from some external component, use this to hide the default submit button
     */
    showSubmitBtn: {
      type: Boolean,
      default: true,
    },
    showMediaBar: {
      type: Boolean,
      default: true,
    },
    focusEditorAtCreation: {
      type: Boolean,
      default: false,
    },
    profileAvatarUri: {
      type: String,
      default: '',
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    hasFocusBorder: {
      type: Boolean,
      default: false,
    },
    hasPadding: { type: Boolean, default: true },
    pollQuestion: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      currentMedia: '',
      imagesToUpload: null,
      gifToUpload: null,
      currentEditorIndex: null,
      disabledByContent: false,
      disabledTooltip: null,
      embed: null,
      postBoxKey: 0,
      pollId: null,
    };
  },
  computed: {
    imagesToDisplay() {
      if (this.gifToUpload) {
        return [{ src: this.gifToUpload.src }];
      } else if (this.imagesToUpload) {
        return this.imagesToUpload.map((image) => ({
          src: image.preview,
        }));
      } else {
        return null;
      }
    },

    disabledMedia() {
      if (this.gifToUpload) {
        return ['image', 'poll'];
      } else if (this.imagesToUpload && this.imagesToUpload.length) {
        return ['gif', 'poll'];
      }

      if (this.disabled) {
        return ['image', 'gif', 'emoji', 'poll'];
      }
      return [];
    },

    isMobileSize() {
      return isMobile();
    },

    computedPollQuestion() {
      return this.getBody();
    },
  },
  watch: {
    clear() {
      if (this.clear) {
        this.editor.setText('');
        this.imagesToUpload = null;
        this.gifToUpload = null;
        this.currentMedia = '';
      }
    },
    submitFromExternal() {
      if (this.submitFromExternal) {
        this.submitPost();
      }
    },
    currentMedia(newMedia) {
      if (this.editor) {
        const newPlaceholder = newMedia.includes('poll')
          ? this.$t('poll_question_hint')
          : this.placeholder;
        this.editor.root.dataset.placeholder = newPlaceholder;
        this.editor.focus({ preventScroll: true });
      }
      this.$emit('onCurrentMediaChange', this.currentMedia.includes('poll'));
    },
  },

  methods: {
    resetPostBox() {
      this.postBoxKey += 1;
    },
    toggleDisable(disable) {
      this.disabledByContent = disable.disabled;
      switch (disable.reason) {
        case PostDisabledReason.CONTENT:
          this.disabledTooltip = this.$t('post_disabled_bad_content');
          break;
        case PostDisabledReason.LENGTH:
          this.disabledTooltip = this.$t('post_disabled_length');
          break;
        default:
          return;
      }
    },
    submitPost() {
      const images = this.getImageFormData();
      const mentions = this.getMentions();
      const body = !this.pollId ? this.getBody(mentions) : null;

      if (
        body ||
        images ||
        this.gifToUpload ||
        mentions.length ||
        this.pollId
      ) {
        this.$emit('onSubmit', {
          body: body,
          mentions,
          gif: this.gifToUpload ? this.gifToUpload.id : null,
          images,
          targetType: this.targetType,
          target: this.target,
          embed: this.embed,
          pollId: this.pollId,
        });
        this.resetPostBox();
      }
    },
    getBody(mentions = []) {
      let body = this.editor
        .getContents()
        .ops.map((op) => {
          if (typeof op.insert === 'string') {
            return op.insert;
          }
          if (op.insert.mention) {
            return op.insert.mention.denotationChar + op.insert.mention.value;
          }
        })
        .join('')
        .replace(/\n$/, '');

      mentions.forEach((mention) => {
        if (mention.type === '@') {
          body = body.replace(mention.value, mention.target);
        }
      });
      return body;
    },
    getMentions() {
      const contents = this.editor.getContents();
      const res = [];

      contents.forEach((e) => {
        if (e.insert && e.insert.mention) {
          res.push({
            type: e.insert.mention.denotationChar,
            target: e.insert.mention.id,
            value: e.insert.mention.value,
          });
        }
      });

      if (this.mentionedPost) {
        res.push({ type: 'POST', target: this.mentionedPost.postId });
      }
      return res;
    },
    getImageFormData() {
      if (this.imagesToUpload && this.imagesToUpload.length) {
        return this.imagesToUpload.map((e) => e.id);
      } else {
        return null;
      }
    },
    async handleImage(files) {
      if ((this.imagesToUpload || []).length + files.length > 4) {
        this.$toast.error(this.$t('max_image_to_upload'));
      } else {
        files = await this.uploadImages(files);
        this.imagesToUpload = [...(this.imagesToUpload || []), ...files];
      }
    },
    removeImage(index) {
      if (this.gifToUpload) {
        this.gifToUpload = null;
      } else {
        this.$emit('removeImage', this.imagesToUpload[index].id);
        this.imagesToUpload.splice(index, 1);
      }
    },
    handleSelectMedia(selectedMedia) {
      if (this.currentMedia === selectedMedia) {
        this.currentMedia = '';
      } else {
        this.currentMedia = selectedMedia;
      }
    },
    selectEmoji(emoji) {
      const tmpIndex = this.currentEditorIndex || 0;
      this.editor.insertText(this.currentEditorIndex || 0, emoji.data, 'user');
      this.currentEditorIndex = tmpIndex + emoji.data.length;
      this.currentMedia = '';
    },
    handleClickOutsideEmojiPicker() {
      this.currentMedia = '';
    },
    onClickGIF(gif) {
      this.gifToUpload = gif;
      this.currentMedia = '';
    },
    calcIndexWithMentions() {
      const textLength =
        this.editor.getText().length <= 0
          ? 0
          : this.editor.getText().length - 1;
      return textLength + this.getMentionsLength();
    },
    getEditor(editor) {
      this.editor = editor;
      this.editor.on('text-change', () => {
        this.currentEditorIndex = this.calcIndexWithMentions();
      });
      this.editor.on('selection-change', (selection) => {
        if (selection) {
          this.currentEditorIndex = selection.index;
        }
      });
    },
    getMentionsLength() {
      const mentions = this.getMentions();
      let newMentions = [];
      if (mentions.length !== 0) {
        newMentions = mentions.filter((obj) => obj.type !== 'POST');
      }

      return newMentions.length;
    },
    onRemovePoll() {
      this.currentMedia = '';
    },
    onCreatePoll(pollId) {
      this.pollId = pollId;
      this.submitPost();
    },
  },
};
