export default {
  showCreatePostModal(context, payload) {
    context.commit('HANDLE_SHOW_CREATE_POST_MODAL', payload);
  },

  showCreatePortfolioModal(context) {
    context.commit('HANDLE_SHOW_CREATE_PORTFOLIO_MODAL');
  },
  showLegalDisclaimerModal(context) {
    context.commit('HANDLE_SHOW_LEGAL_DISCLAIMER_MODAL');
  },
  showImageSliderModal(context, payload) {
    context.commit('HANDLE_SHOW_IMAGE_SLIDER_MODAL', payload);
  },
  showSelectProfileModal(context) {
    context.commit('HANDLE_SHOW_SELECT_PROFILE_MODAL');
  },

  mouseEnterMentionCard(context) {
    context.commit('SET_MENTION_CARD_HOVERED', true);
    context.commit(
      'SET_MENTION_CARD_HOVERED_ID',
      context.state.currentMentionCard.profile.profileId
    );
    context.commit('MOUSE_ENTER_MENTION_CARD');
  },
  mouseLeaveMentionCard(context) {
    context.commit('SET_MENTION_CARD_HOVERED', false);
    context.commit('SET_MENTION_CARD_HOVERED_ID', null);
    context.commit('MOUSE_LEAVE_MENTION_CARD');
    context.commit('HIDE_MENTION_CARD_DONE');
  },
  mouseEnterMention(context, data) {
    context.commit('SET_MENTION_HOVERED', true);
    context.commit('SET_MENTION_HOVERED_ID', data.id);

    setTimeout(() => {
      if (
        context.state.mentionHovered &&
        context.state.hoveredMentionId == data.id &&
        (!context.state.lastMentionCard ||
          !context.state.lastMentionCard.profile ||
          context.state.lastMentionCard.profile.profileId !== data.id ||
          context.state.lastMentionCard.pos != data.pos)
      ) {
        context.commit('SET_MENTION_CARD_POS', data.pos);
        if (data.profile && data.profile.profileId) {
          context.commit('SET_MENTION_CARD_PROFILE', data.profile);
        }
        return this.dispatch('api/getProfileById', data.id)
          .then((res) => {
            context.commit('SET_MENTION_CARD_PROFILE', res.data);
          })
          .catch((err) => {
            context.commit('SET_MENTION_CARD_ERROR', err);
          })
          .finally(() => {
            context.commit('SET_MENTION_CARD_LOADING', false);
          });
      } else if (
        context.state.lastMentionCard &&
        context.state.lastMentionCard.profile &&
        context.state.lastMentionCard.profile.profileId === data.id &&
        context.state.lastMentionCard.pos == data.pos
      ) {
        context.commit('SET_MENTION_CARD_LAST_MENTION_CARD');
      }
    }, 400);
  },
  mouseLeaveMention(context, id) {
    context.commit('SET_MENTION_HOVERED', false);
    context.commit('SET_MENTION_CARD_HOVERED_ID', null);
    context.commit('MOUSE_LEAVE_MENTION');
    setTimeout(() => {
      if (
        (!context.state.mentionHovered ||
          context.state.hoveredMentionId != id) &&
        !context.state.mentionCardHovered
      ) {
        context.commit('HIDE_MENTION_CARD_DONE');
      }
    }, 150);
  },
  followMentionCard(context) {
    return this.dispatch(
      'api/followProfile',
      context.state.currentMentionCard.profile.profileId
    )
      .then((res) => {
        context.commit('SET_MENTION_CARD_IS_FOLLOWED', res.data.isFollowed);
      })
      .catch((err) => {
        context.commit('SET_MENTION_CARD_FOLLOW_ERRROR', err);
      });
  },

  // Reset Password modal
  showPasswordReset(context) {
    context.commit('INIT_PASSWORD_RESET');
    context.commit('SET_SHOW_PASSWORD_RESET', true);
  },
  hidePasswordReset(context) {
    context.commit('SET_SHOW_PASSWORD_RESET', false);
  },
  passwordResetNextStep(context) {
    context.commit('PASSWORD_RESET_NEXT_STEP');
  },

  // Create operation modal
  showCreatePortfolioOperationModal(context, payload) {
    if (payload.show) {
      context.commit('HANDLE_SHOW_CREATE_PORTFOLIO_OPERATION_MODAL', payload);
    } else {
      context.commit('RESET_CREATE_PORTFOLIO_OPERATION_MODAL');
    }
  },
  switchCreatePortfolioOperation(context, payload) {
    context.commit(
      'RESET_CREATE_PORTFOLIO_OPERATION_MODAL_ON_OPERATION_CHANGE'
    );
    context.commit('HANDLE_SHOW_CREATE_PORTFOLIO_OPERATION_MODAL', payload);
  },
  removeSymbolFromPortfolioOperation(context) {
    context.commit('REMOVE_SYMBOL_FROM_PORTFOLIO_OPERATION');
  },
  submitPortfolioOperationForm(context, payload) {
    context.commit('SUBMIT_PORTFOLIO_OPERATION_FORM', payload);
  },
  resetSubmitOperationState(context) {
    context.commit('RESET_SUBMIT_OPERATION_STATE');
  },
  setHelpCenterObjectRes(context, payload) {
    localStorage.setItem('hasClickedHelpCenterButton', 'true');
    context.commit('HANDLE_SET_HELP_CENTER', payload);
  },
  setShowProfileCardRes(context, payload) {
    context.commit('HANDLE_SET_SHOW_PROFILE_CARD', payload);
  },
  setOperationSymbol(context, payload) {
    context.commit('SET_OPERATION_SYMBOL', payload);
  },
  getTickerStockValue(context, payload) {
    context.commit('GET_TICKER_VALUE_INIT');
    return this.dispatch('api/getTickerStockValue', payload)
      .then((res) => {
        context.commit('GET_TICKER_VALUE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_VALUE_ERROR', err);
      });
  },
  getCryptoTickerStockRangeValue(context, payload) {
    context.commit('GET_TICKER_VALUE_INIT');
    return this.dispatch('api/getCryptoTickerStockRangeValue', payload)
      .then((res) => {
        context.commit('GET_TICKER_VALUE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_VALUE_ERROR', err);
      });
  },
  getCryptoTickerStockValue(context, payload) {
    context.commit('GET_TICKER_VALUE_INIT');
    return this.dispatch('api/getCryptoTickerStockValue', payload)
      .then((res) => {
        context.commit('GET_TICKER_VALUE_SUCCESS', res.data);
      })
      .catch((err) => {
        context.commit('GET_TICKER_VALUE_ERROR', err);
      });
  },
  setCreateOperationModalModalPortfolio(context, portfolio) {
    context.commit('SET_CREATE_OPERATION_MODAL_PORTFOLIO', portfolio);
  },

  // Portfolio empty state modal
  showPortfolioEmptyStateModal(context) {
    context.commit('SHOW_PORTFOLIO_EMPTY_STATE_MODAL');
  },

  // Giphy modal
  setGiphyLastTrendingSearch(context) {
    context.commit('SET_LAST_GIPHY_TRENDING_SEARCH');
  },

  // Compare Tickers Modal
  toggleCompareTickersModal(context, payload) {
    context.commit('SET_COMPARE_TICKERS_MODAL', payload);
  },
  // Compare Portfolios Modal
  toggleComparePortfoliosModal(context, payload) {
    context.commit('SET_COMPARE_PORTFOLIOS_MODAL', payload);
  },

  // Team Portfolio Share Modal
  showManageTeamPortfolioModal(context) {
    context.commit('TOGGLE_SHOW_PORTFOLIO_SHARE_MODAL');
  },
  hideAllModals(context) {
    context.commit('HIDE_ALL_MODALS');
  },

  // Create League Modal
  toggleCreateLeagueModal(context) {
    context.commit('TOGGLE_SHOW_CREATE_LEAGUE_MODAL');
  },

  showPortfolioAssetOperationModalFooter(context, payload) {
    context.commit('SHOW_PORTFOLIO_ASSET_OPERATION_MODAL', payload);
  },

  closeEmailConfirmationBanner(context) {
    localStorage.setItem('hasClosedEmailConfirmationBanner', 'true');
    context.commit('CLOSE_EMAIL_CONFIRMATION_BANNER');
  },
};
