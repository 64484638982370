import {
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from '@microsoft/signalr';
import { Store } from 'vuex';
import { RootState } from '~/store';
import { ContainsHubConnection } from '..';
import { MessageToConversation } from '~/types/messages/messages';

export interface MessagingConnection extends ContainsHubConnection {
  sendChatMessage(
    receiver: string,
    id: string,
    conversationId: string,
    participantIds: [],
    senderUserId: string,
    text: string,
    gifUrl: string,
    imageToBase64: string,
    reaction: string,
    reactionToMessageId: string,
    replyToMessageId: string,
    readBy: []
  ): Promise<object>;
  getConversations(
    userId: string,
    pageIndex: number,
    pageSize: number,
    conversations: MessageToConversation[]
  ): Promise<object>;
  getConversationByConversationId(
    userId: string,
    conversationId: string,
    pageIndex: number,
    pageSize: number,
    conversations: MessageToConversation[]
  ): Promise<object>;
  getMessageBatchById(messageIds: string[]): Promise<object>;
  getUsers(
    userId: string,
    query: string,
    topResults: number,
    users: UserDto[]
  ): Promise<object>;
  removeReaction(id: string): Promise<object>;
  updatePrivacy(id: string, privacyOption: string): Promise<object>;
  blockProfile(
    authorId: string,
    reportedUserId: string,
    isBlock: boolean
  ): Promise<object>;
}

export default function messagingHub(
  store: Store<RootState>,
  jwt: string
): MessagingConnection {
  const hub = new HubConnectionBuilder()
    .withUrl(`${process.env.NUXT_ENV_API_URL}/subscribe/messages`, {
      accessTokenFactory: () => jwt,
    })
    .configureLogging(
      ['local', 'development'].includes(process.env.NODE_ENV ?? '')
        ? LogLevel.Debug
        : LogLevel.None
    )
    .withAutomaticReconnect()
    .build();

  hub.onreconnecting((error) => {
    console.assert(hub.state === HubConnectionState.Reconnecting);
    console.log('Reconnecting...', error);
  });

  hub.onreconnected((connectionId) => {
    console.assert(hub.state === HubConnectionState.Connected);
    console.log('Reconnected with connectionId:', connectionId);
  });

  hub.on('RemoveReaction', (receiver: string, id: string) => {
    store.dispatch('messaging/removeReaction', { id });
    store.dispatch('messaging/removeNotification', { id });
  });

  hub.on(
    'SendMessage',
    (
      receiver: string,
      id: string,
      conversationId: string,
      participantIds: [],
      senderUserId: string,
      text: string,
      gifUrl: string,
      imageToBase64: string,
      reaction: string,
      reactionToMessageId: string,
      replyToMessageId: string,
      readBy: []
    ) => {
      store.dispatch('messaging/addMessage', {
        id: id,
        conversationId: conversationId,
        participantIds: participantIds,
        senderUserId: senderUserId,
        text: text,
        updatedDateUtc: Date.now(),
        gifUrl: gifUrl,
        imageToBase64: imageToBase64,
        reaction: reaction,
        reactionToMessageId: reactionToMessageId,
        replyToMessageId: replyToMessageId,
        readBy: readBy,
      });
    }
  );

  hub.on('UpdateOnlineStatus', (receiver, _onlineUsers) => {
    store.dispatch('messaging/updateOnlineStatus', { _onlineUsers });
  });

  hub.on('updatePrivacy', () => {
    store.dispatch('messaging/setPrivacyUpdatedDateTime');
  });

  hub.on('blockProfile', () => {
    store.dispatch('messaging/setBlocksUpdatedDateTime');
  });

  return {
    hub,
    sendChatMessage(
      id,
      conversationId,
      participantIds,
      senderUserId,
      text,
      gifUrl,
      imageToBase64,
      reaction,
      reactionToMessageId,
      replyToMessageId,
      readBy
    ) {
      return hub.invoke(
        'SendMessage',
        id,
        conversationId,
        participantIds,
        senderUserId,
        text,
        gifUrl,
        imageToBase64,
        reaction,
        reactionToMessageId,
        replyToMessageId,
        readBy
      );
    },
    getConversations(userId, pageIndex, pageSize) {
      return hub.invoke('GetConversations', userId, pageIndex, pageSize);
    },
    getConversationByConversationId(
      userId,
      conversationId,
      pageIndex,
      pageSize
    ) {
      return hub.invoke(
        'getConversationByConversationId',
        userId,
        conversationId,
        pageIndex,
        pageSize
      );
    },
    getMessageBatchById(messageIds) {
      return hub.invoke('GetMessageBatchById', messageIds);
    },
    async getUsers(userId, query, topResults) {
      return hub.invoke('GetUsers', userId, query, topResults);
    },
    async removeReaction(id) {
      return hub.invoke('RemoveReaction', id);
    },
    updatePrivacy(id, privacyOption) {
      return hub.invoke('UpdatePrivacy', id, privacyOption);
    },
    blockProfile(authorId, reportedUserId, isBlock) {
      return hub.invoke('BlockProfile', authorId, reportedUserId, isBlock);
    },
  };
}
export interface UserDto {
  userId: number;
  profileId: number;
  username: string;
  displayName: string;
  avatarUri: string;
}
