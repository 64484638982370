export default function ({ $toast }, inject) {
  // Create a custom axios instance
  const notify = {
    success(
      msg = 'Success !',
      options = {
        className: ['toasted-success'],
        duration: 2500,
        // Disabling swipe functionality because of the weird behavior on mobile.
        // If user clicks on the toast message and swipes up/down it will autimatically remove the toast,
        // toast won't be visible but it creates some invisible container in the background that keeps all
        // toast messages removed in the same way. Whenever there is a new toast message it will be placed
        // at the bottom of that container.
        closeOnSwipe: false,
      }
    ) {
      $toast.success(msg, {
        ...options,
        icon: 'task_alt',
      });
    },
    error(
      msg = 'Error !',
      options = {
        className: ['toasted-error'],
        duration: 2500,
        closeOnSwipe: false,
      }
    ) {
      $toast.error(msg, { ...options, icon: 'info_outlined' });
    },
    info(
      msg = 'Info !',
      options = {
        className: ['toasted-info'],
        duration: 2500,
        closeOnSwipe: false,
      }
    ) {
      $toast.info(msg, { ...options, icon: 'info_outlined' });
    },
    warning(
      msg = 'Warning !',
      options = {
        className: ['toasted-warning'],
        duration: 2500,
        closeOnSwipe: false,
      }
    ) {
      $toast.info(msg, { ...options, icon: 'info_outlined' });
    },
  };

  // Inject to context as $api
  inject('notify', notify);
}
