export const messages = (state) => state.messages;
export const conversations = (state) => state.conversations;
export const users = (state) => state.users;
export const onlineUsers = (state) => state.onlineUsers;
export const messageNotifications = (state) => state.messageNotifications;
export const messageNotificationCount = (state) =>
  state.messageNotificationCount;
export const messagesWithReplies = (state) => state.messagesWithReplies;
export const profilesMessagePrivacySettings = (state) =>
  state.profilesMessagePrivacySettings;
export const privacyUpdatedDateTime = (state) => state.privacyUpdatedDateTime;
export const blocksUpdatedDateTime = (state) => state.blocksUpdatedDateTime;
export const landedFromProfileId = (state) => state.landedFromProfileId;

export default {
  messages,
  conversations,
  users,
  onlineUsers,
  messageNotifications,
  messageNotificationCount,
  messagesWithReplies,
  profilesMessagePrivacySettings,
  privacyUpdatedDateTime,
  blocksUpdatedDateTime,
  landedFromProfileId,
};
