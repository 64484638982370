
import PortfolioStatsCard from './PortfolioStatsCard';

export default {
  name: 'PriceCard',
  components: {
    PortfolioStatsCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: true,
    },
    currency: {
      type: String,
      default: '€',
    },
    change: {
      type: Number,
      default: null,
    },
    displayFullNumber: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    borderRounded: {
      type: Boolean,
      default: true,
    },
  },
};
